import React, { useEffect, useState } from "react";
import Button from "../../components/ui/Button";
import CenterIcon from "../../components/ui/CenterIcon";
import Container from "../../components/ui/Container";
import DayCardTable from "../../components/ui/DayCardTable";
const StudyPlanWeeksSection = ({
    name = "الأسبوع الأول",
    initialShowingWeek = false,
    weekDescription = "“ هنا تكتب ملاحظات الخاصة بكل اسبوع”",
    dateFrom = "",
    dateTo = "",
    RelatedDays = [],
    finishedSubjects = [],
}) => {
    const [showWeek, setShowWeek] = useState(initialShowingWeek);
    const [weekStatus, setWeekStatus] = useState("");
    const [fillStatusClass, setFillStatusClass] = useState(
        "fill-secYellow-800 dark:fill-secYellow-300"
    );
    function parseDate(dateString) {
        const parts = dateString.split("/");
        const day = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10) - 1;
        const year = parseInt(parts[2], 10);
        return new Date(year, month, day);
    }

    const fromDate = parseDate(dateFrom);
    const toDate = parseDate(dateTo);
    const currentDateObj = new Date();
    useEffect(() => {
        // console.log("currentDateObj:", currentDateObj);
        // console.log("fromDate:", fromDate);
        // console.log("toDate:", toDate);

        if (currentDateObj >= fromDate && currentDateObj <= toDate) {
            setWeekStatus("الحالي");
            setFillStatusClass("fill-[#D1FFB5] dark:fill-[#485F3A]");
            setShowWeek(true);
        } else if (currentDateObj > toDate) {
            setFillStatusClass("fill-secYellow-800 dark:fill-secYellow-300");
            setWeekStatus("فات ");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateFrom, dateTo]);

    return (
        <section className={`flex-center-both flex-col space-y-10 `}>
            <div className="w-full flex justify-between">
                <div className="relative flex h-fit items-center justify-center">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="231"
                        height="101"
                        viewBox="0 0 231 101"
                        fill="none"
                        className="absolute right-0 -z-10"
                    >
                        <ellipse
                            cx="115.5"
                            cy="50.5"
                            rx="115.5"
                            ry="50.5"
                            className={fillStatusClass}
                        />
                    </svg>
                    <h1 className=" md:font-w-bold text-3xl md:text-4xl  ">
                        {name}
                        <span className="font-w-bold">"{weekStatus}"</span>
                    </h1>
                </div>
                <div className="pt-2">
                    <Button
                        className="flex-center-both space-x-2 font-w-bold space-x-reverse border-purple-500 text-purple-500 smooth group bg- border-0 underline"
                        onClick={() => setShowWeek(!showWeek)}
                    >
                        {!showWeek ? (
                            <span className="">عرض الأسبوع</span>
                        ) : (
                            <span className="">إخفاء الأسبوع</span>
                        )}
                        <CenterIcon
                            className={`transform smooth text-purple-500 ${
                                showWeek ? "pb-1 -rotate-180 " : "pt-0.5 rotate-0"
                            }`}
                            icon="bxs:up-arrow"
                        />
                    </Button>
                </div>
            </div>

            <Container className="flex-center-both w-full gap-8 flex-col">
                <p className="text-center font-h2">{weekDescription}</p>

                {!showWeek ? (
                    <Button
                        className="flex-center-both space-x-2 font-w-bold bg-[#F2F2F2] dark:bg-slate-700 space-x-reverse border-black  text-black gap-3 smooth group border "
                        onClick={() => setShowWeek(!showWeek)}
                    >
                        أضغط هنا لإظهار محتوي الأسبوع
                        <CenterIcon icon="svg-spinners:3-dots-fade" />
                    </Button>
                ) : (
                    <>
                        <div className="flex flex-col gap-2 w-full">
                            {RelatedDays.map((day, index) => (
                                <DayCardTable
                                    key={index}
                                    index={index}
                                    {...day}
                                    currentDateObj={currentDateObj}
                                    finishedSubjects={finishedSubjects}
                                />
                            ))}
                        </div>
                    </>
                )}
            </Container>
        </section>
    );
};

export default StudyPlanWeeksSection;
