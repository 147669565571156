import React from "react";

const HoursMiniCards = () => {
    const FixedHours = [
        {
            name: "النوم",
            Range: "6 الي 8 ساعات",
        },
        {
            name: "دروسك",
            Range: "5 الي 6 ساعات",
        },
        {
            name: "البريك",
            Range: "ساعتين",
        },
        {
            name: "اكل وشرب",
            Range: "ساعة",
        },
    ];

    return (
        <>
            <div className="flex flex-wrap gap-4 justify-center lg:justify-start">
                {FixedHours.map((card, index) => (
                    <div
                        key={index}
                        className="flex overflow-hidden relative w-[250px] h-[45px] bg-primSky-800 dark:bg-primSky-300 rounded-xl border border-solid border-[#00000020]"
                    >
                        <div className="bg-secYellow-800 w-[110px] -mr-2 flex-center-both px-3 dark:bg-secYellow-300 border border-solid border-[#00000020] rounded-xl ">
                            <p className="font-w-bold text-[16px]">{card.name}</p>
                        </div>
                        <div className="px-3 flex-center-both w-1/2">
                            {" "}
                            <p className="text-center">{card.Range}</p>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default HoursMiniCards;
