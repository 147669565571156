import React, { useEffect, useState } from "react";
import StudyPlanMainSection from "../sections/home/StudyPlanMainSection";
import Container from "../components/ui/Container";
import Heading from "../components/NewUi/heading/Heading";
import { isMultiYear } from "../services/defaultSettings";
import InputField from "../components/form/elements/InputField";
import { handleInputChange } from "../services/formServices";
import { years } from "../services/yearSevices";
import auth from "../services/authServices";
import http from "../services/httpServices";
import StudyPlanWeeksSection from "../sections/home/StudyPlanWeeksSection";
import { useWeek } from "../hooks/useWeek";
import LoadingIcon from "../components/ui/LoadingIcon";

const MyStudy = () => {
    const [filterData, setFilterData] = useState({
        year: 3,
        department_id: 0,
    });
    const [departments, setDepartments] = useState([]);

    const { weeks, setWeeks } = useWeek();
    const [finishedSubjects, setFinishedSubjects] = useState([]);

    const [placeholder, setPlaceholder] = useState(
        <div className="font-h2 col-span-1 md:col-span-2 lg:col-span-3 py-20">
            <span className="flex-center-both space-x-3 space-x-reverse">
                <LoadingIcon className={"font-h1 text-teal-600 dark:text-teal-400"} />
                <span>يتم الآن تحميل جدولك</span>
            </span>
        </div>
    );

    const getWeeks = async () => {
        const token = auth.getToken();
        const config = auth.getAuthConfig(token);
        const { data } = await http.get(
            `/api/study_planner_weeks/years/${filterData.year}/${filterData.department_id}`,
            config
        );
        setWeeks(data.weeks);
        setFinishedSubjects(data.finishedSubjects);
        // console.log(data);
    };

    const toUseYears = years.filter((value, index) => index < 3);

    const getDepartments = async () => {
        const token = auth.getToken();
        const config = auth.getAuthConfig(token);
        const { data } = await http.get(`api/years/${filterData.year}/departments/options`, config);

        setDepartments([]);
        if (data.length < 1) {
            setFilterData({ ...filterData, department_id: 0 });
        } else {
            setFilterData({ ...filterData, department_id: data[0]["value"] });
        }
        setDepartments(data);
        console.log(filterData.department_id);
    };
    useEffect(() => {
        getDepartments();
    }, [filterData.year]);
    useEffect(() => {
        if (filterData.year && filterData.department_id && filterData.department_id > 0) {
            getWeeks();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterData.department_id, filterData.year]);
    return (
        <>
            <StudyPlanMainSection />
            <section className="min-h-[80vh] relative overflow-hidden my-10">
                <Container className="flex-center-both flex-col gap-20">
                    <div className="flex-center-both flex-col space-y-12">
                        <div className="flex flex-col items-center justify-center h-fit gap-10">
                            <Heading title="أختار" subtitle="جدولك" />

                            <p className="text-center w-full text-lg md:text-2xl lg:max-w-[800px]">
                                اختار سنتك الدراسية والشعبه العلمية عشان يظهرلك الجدول المخصص ليك
                                متقسم بالأسابيع و الأيام وكل يوم هتلاقي المواد وانتبه جيداً{" "}
                                <span className="text-secYellow-400 font-w-bold">للملاحظات.</span>
                            </p>
                        </div>
                        <div className="flex-center-both md:flex-row flex-col px-10 py-3 rounded-md md:space-x-4 md:space-x-reverse space-y-4 md:space-y-0 bg-yellow-300 dark:bg-yellow-500 smooth clr-text-primary">
                            {isMultiYear ? (
                                <InputField
                                    onChange={handleInputChange}
                                    data={filterData}
                                    setData={setFilterData}
                                    type="select"
                                    options={toUseYears}
                                    id="year"
                                    placeholder="اختر الصف الدراسي"
                                    // errors={errors}
                                />
                            ) : (
                                ""
                            )}
                            <InputField
                                onChange={handleInputChange}
                                data={filterData}
                                setData={setFilterData}
                                type="select"
                                options={departments}
                                id="department_id"
                                placeholder="اختر الشعبة"
                            />
                        </div>
                    </div>
                    <div className="w-full px-0 md:px-5 lg:px-10 flex flex-col space-y-10 md:space-y-20  ">
                        {!filterData.department_id && !filterData.department_id > 0 ? (
                            <div className="max-w-5xl bg-red-200 dark:bg-slate-900 flex-center-both text-2xl flex-col gap-7 font-bold rounded-3xl py-10 px-10 text-center">
                                <LoadingIcon
                                    className={"font-h1 text-teal-600 dark:text-teal-400"}
                                />
                                تأكد من اختيار السنة الدراسية والشعبة حتي يمكنك عرض الجدول الخاص بك
                            </div>
                        ) : (
                            <>
                                {weeks.length < 1 && placeholder}
                                {weeks.length > 0 &&
                                    weeks.map((week, index) => (
                                        <StudyPlanWeeksSection
                                            key={index}
                                            {...week}
                                            finishedSubjects={finishedSubjects}
                                        />
                                    ))}
                            </>
                        )}
                    </div>
                </Container>
            </section>
        </>
    );
};

export default MyStudy;
