import React, { useState } from "react";
import CenterIcon from "../../components/ui/CenterIcon";
import InputField from "../../components/form/elements/InputField";
import InputIcon from "../../components/form/elements/InputIcon";
import { handleInputChange } from "../../services/formServices";
import Button from "../../components/ui/Button";
import { useNavigate } from "react-router-dom";
import Form from "../../components/form/elements/Form";

const SearchComponent = ({ className, isLargeScreen = true }) => {
    const [isOpened, setIsOpened] = useState(false);

    const [data, setData] = useState({
        search: "",
    });
    const navigate = useNavigate();
    const handleSubmit = () => {
        setIsOpened(false);
        navigate(`/search?s=${data.search}`);
    };
    return (
        <div className={`${className}`}>
            {isOpened ? (
                <div className="fixed inset-0 w-full h-fullsmooth">
                    <div className="flex-center-both w-full h-full relative">
                        <div
                            className="absolute w-full h-full inset-0 bg-slate-100 dark:bg-slate-900 dark:bg-opacity-70 bg-opacity-70 smooth cursor-pointer"
                            onClick={() => setIsOpened(false)}
                        ></div>
                        <Form>
                            <div className="relative flex-center-both flex-col space-y-5 bg-secondary-container rounded-md p-5">
                                <div className="w-full text-center font-h3 font-w-bold px-5">
                                    ابحث في كورسات الموقع ..
                                </div>
                                <InputField
                                    id="search"
                                    placeholder="ابحث"
                                    icon={<InputIcon icon="ic:outline-search" />}
                                    type="text"
                                    data={data}
                                    setData={setData}
                                    onChange={handleInputChange}
                                    className="w-full"
                                    autoFocus={true}
                                />
                                <Button color="blue" className="w-full" onClick={handleSubmit}>
                                    بحث
                                </Button>
                            </div>
                        </Form>
                    </div>
                </div>
            ) : (
                ""
            )}
            <div
                className={`mr-2 font-h2 flex-center-both space-x-2 space-x-reverse group cursor-pointer`}
                onClick={() => setIsOpened(true)}
            >
                <CenterIcon
                    icon="ic:outline-search"
                    className="group-hover:bg-slate-200 dark:group-hover:bg-slate-800  rounded-md p-2 clr-text-primary smooth"
                />
                {isLargeScreen ? (
                    <>
                        <div className="overflow-hidden relative font-smaller font-w-bold">
                            <div className="opacity-0">ابحث في الموقع</div>
                            <div className="absolute inset-0 translate-x-[100%] group-hover:translate-x-0 smooth">
                                ابحث في الموقع
                            </div>
                            {/* <div className="group-hover:block"></div> */}
                        </div>
                    </>
                ) : (
                    ""
                )}
            </div>
        </div>
    );
};

export default SearchComponent;
