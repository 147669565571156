import React, { useEffect, useState } from "react";
import Release from "../../assets/Updated-imagery/release.svg";
import Button from "./Button";
import { printUnit } from "../../utils/ar";
import auth from "../../services/authServices";
import http from "../../services/httpServices";

const DayCardTable = ({
    currentDateObj = {},
    date = "13/4/2024",
    subjectsData = [],
    finishedSubjects = [],
}) => {
    const [showDay, setShowDay] = useState(false);

    const [finishedRows, setFinishedRows] = useState(Array(subjectsData.length).fill(false));

    // this is used to make finishedRows Dynamic for department table
    useEffect(() => {
        if (finishedRows.length !== subjectsData.length) {
            setFinishedRows(Array(subjectsData.length).fill(false));
        }
    }, [subjectsData, finishedRows.length]);

    // this is used to set finishedSubjects if found 
    useEffect(() => {
        finishedSubjects.forEach((subject) => {
            const index = subjectsData.findIndex((item) => item.subject_id === subject.id);
            if (index !== -1 && !finishedRows[index]) {
                setFinishedRows((prevState) => {
                    const newState = [...prevState];
                    newState[index] = true;
                    return newState;
                });
            }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finishedSubjects, subjectsData]);
    
    function parseDate(dateString) {
        const parts = dateString.split("/");
        const day = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10) - 1;
        const year = parseInt(parts[2], 10);
        return new Date(year, month, day);
    }
    const dayDate = parseDate(date);

    const formattedDate = dayDate.toLocaleDateString("ar-EG", {
        weekday: "long",
        day: "2-digit",
        month: "long",
        year: "numeric",
        timeZone: "Africa/Cairo",
    });

    const parts = formattedDate.split("، ");
    const DayName = parts[0];
    const currentDateParts = parts[1].split(" ");
    const currentDate = `${currentDateParts[0]} / ${currentDateParts[1]}`;
    // console.log(`currentDate ${currentDateParts}`);

    const toggleFinished = async (index, subjectId) => {
        const token = auth.getToken();
        const config = auth.getAuthConfig(token);
        console.log(`subjectID : ${subjectId}`);
        try {
            await http.post(
                `/api/study_planner_subjects/updateSubjectFinish`,
                { subject_id: subjectId },
                config
            );
            setFinishedRows((prevState) => {
                const newState = [...prevState];
                newState[index] = !newState[index];
                return newState;
            });
        } catch (error) {
            // console.error("", error);
        }
    };

    const totalStudiedHours = subjectsData.reduce((total, subject) => {
        return total + parseFloat(subject.estimatedTime);
    }, 0);

    useEffect(() => {
        // if day date is similar to current data open the table automatically
        if (currentDateObj.toDateString() === dayDate.toDateString()) {
            setShowDay(!showDay);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="relative">
            <div
                onClick={() => setShowDay(!showDay)}
                className={`w-full z-10 relative flex justify-start md:justify-center items-center px-2 md:px-0 rounded-3xl py-5 smooth transform hover:cursor-pointer hover:scale-[100.5%] hover:shadow-md  bg-[#CFF1FC] dark:bg-primSky-300 ${
                    showDay && `shadow-md`
                }`}
            >
                <div className="relative w-fit flex h-fit  items-center justify-start md:justify-center ">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="109"
                        height="62"
                        viewBox="0 0 109 62"
                        fill="none"
                        className="absolute right-0 "
                    >
                        <ellipse
                            cx="54.5"
                            cy="31"
                            rx="54.5"
                            ry="31"
                            className="fill-[#D6CCFA] dark:fill-[#3f154988]"
                        />
                    </svg>
                    <h1 className=" md:font-w-bold flex-center-both text-lg md:text-3xl relative z-10 flex gap-2  ">
                        <span>يوم {DayName}</span>
                        <span>-</span>
                        <span className="text-xl">الموافق “{currentDate}”</span>
                    </h1>
                </div>
                <img
                    src={Release}
                    className={`transform smooth text-purple-500 ${
                        showDay ? "pb-1 -rotate-180 " : "pt-0.5 rotate-0"
                    } absolute left-5 md:left-10 w-6 md:w-8`}
                    alt="releaseDay"
                />
            </div>
            {showDay && (
                <div
                    className={`fade-down-in h-full py-14 px-5  overflow-x-auto bg-[#F4F4F4] dark:bg-slate-800 -mt-7`}
                >
                    <table className="table-auto w-full table-study-style rounded-xl">
                        <thead className="py-10 bg-secYellow-900 dark:bg-secYellow-300">
                            <tr className="">
                                <th className="h-20 min-w-[150px] md:min-w-min  text-center rounded-tr-xl">
                                    تم الأنتهاء
                                </th>
                                <th className="h-20 min-w-[150px] md:min-w-min text-center ">
                                    {" "}
                                    أسم المادة
                                </th>
                                <th className="h-20 min-w-[150px] md:min-w-min text-center">
                                    الحاجة اللي هتتذاكر / فرع
                                </th>
                                <th className="h-20 min-w-[150px] md:min-w-min text-center">
                                    وقت المذاكره
                                </th>
                                <th className="h-20 min-w-[150px] md:min-w-min text-center w-[50%] rounded-tl-xl">
                                    ملاحظات يجب اتباعها
                                </th>
                            </tr>
                        </thead>
                        <tbody className="py-10">
                            {!subjectsData.length ? (
                                <tr>
                                    <td
                                        colSpan="5"
                                        className="text-center font-w-bold h-20 min-w-[150px] md:min-w-min bg-orange-200 dark:bg-orange-900"
                                    >
                                        لا يوجد مواد للمذاكره في هذا اليوم خده راحه!
                                    </td>
                                </tr>
                            ) : (
                                subjectsData.map((subject, index) => (
                                    <>
                                        <tr
                                            className={`${
                                                finishedRows[index] &&
                                                `bg-[#DFFFCC] dark:bg-[#20450b] smooth`
                                            }`}
                                        >
                                            <td className="h-20 min-w-[150px] md:min-w-min text-center">
                                                <Button
                                                    onClick={() =>
                                                        toggleFinished(index, subject.subject_id)
                                                    }
                                                    className="bg-[#9FE3F9] dark:bg-primSky-200 w-fit px-4 py-2 font-w-bold rounded-3xl hover:shadow-md  "
                                                >
                                                    {finishedRows[index] ? "شاطر" : "خلصت"}
                                                </Button>
                                            </td>
                                            <td className="h-20 min-w-[150px] md:min-w-min text-center">
                                                {subject.subject}
                                            </td>
                                            <td className="h-20 min-w-[150px] md:min-w-min text-center">
                                                {subject.branch}
                                            </td>
                                            <td className="h-20 min-w-[150px] md:min-w-min text-center">
                                                {/* {subject.estimatedTime} */}
                                                {printUnit(subject.estimatedTime, "دقيقة")}
                                            </td>
                                            <td className="h-20 min-w-[150px] md:min-w-min text-center">
                                                {subject.notes}
                                            </td>
                                        </tr>
                                        {subject.breakTime && (
                                            <tr>
                                                <td
                                                    colSpan="5"
                                                    className="text-center h-10 bg-[#D6CCFA] dark:bg-slate-800"
                                                >
                                                    عندك :{" "}
                                                    <span className="font-w-bold">
                                                        {printUnit(subject.breakTime, "دقيقة")}{" "}
                                                    </span>{" "}
                                                    بريك دلع نفسك
                                                </td>
                                            </tr>
                                        )}
                                    </>
                                ))
                            )}
                            {/* the every method checks all element within arrays are the same or not */}
                            {finishedRows.every((row) => row) && (
                                <tr>
                                    <td
                                        colSpan="5"
                                        className={`text-center font-w-bold h-20 min-w-[150px] md:min-w-min bg-[#c0f7a1] dark:bg-[#20450b] ${
                                            finishedRows.every((row) => row) && `fade-down-in`
                                        }`}
                                    >
                                        عاش أوي كده انت ذاكرت :{" "}
                                        <span className="font-w-bold px-1 text-gray-900">
                                            {printUnit(totalStudiedHours, "دقيقة")}
                                        </span>{" "}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default DayCardTable;
